import { Game } from './game'

export enum TournamentRewardTypeEnum {
  Cash = 1,
  Bonus = 2,
}

export enum TournamentConditionTypeEnum {
  Wager = 1,
  Multiplier = 2,
}

export type TournamentReward<T = any, U = TournamentRewardTypeEnum> = {
  reward: T
  rewardType: U
}

export interface TournamentDetailsData {
  id: number
  rewards: TournamentReward[]
  conditionType: TournamentConditionTypeEnum
  rules: string
  collectionGameId: number
  expiredTime: number
  prizePool?: string
  name?: string
  defaultGames?: Game[]
  totalGames?: number
}

export interface TournamentType {
  id: number
  name: string
  minEligibleVIPLevel: number
  maxEligibleVIPLevel: number
  image: string
  topPrizes: TournamentReward[]
  expiredTime: number
  isJoined: boolean
  prizePool?: string
  details?: TournamentDetailsData
}
export interface TournamentDetailsByGame extends Partial<TournamentDetailsData> {
  isInGame: boolean
}

export type TournamentUserInfo = {
  rank: number
  displayName: string
  userCode: string
  wagerAmount?: string
  multiplier?: string
  prize?: TournamentReward
}

/// Leaderboard
export type TournamentLeaderboardItem = TournamentUserInfo & { background?: string }
export type TournamentLeaderboardUserInfo = {
  aboveUserInfo: TournamentLeaderboardItem
  userInfo: TournamentLeaderboardItem
}

/// Transaction

export interface TournamentTransaction {
  id: number
  name: string
  rank: string
  prize: TournamentReward
  receivedTime: number
  rewardType: TournamentRewardTypeEnum
}

export const TournamentIdQueryParam = 'tournamentId'
export const LastViewTournamenIdtStoreKey = 'lastViewTournamentId'
