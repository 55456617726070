import { createSlice } from '@reduxjs/toolkit'
import {
  updateNotificationUnreadAmount,
  updateOpenNotification,
  updateUnreadNotificationOnSocket,
  updateUnreadPrivateNotificationAmount,
  updateUnreadSystemNotificationAmount,
} from './actions'

export interface NotificationState {
  unreadPrivateAmount: number
  unreadSystemAmount: number
  isNotificationOpened: boolean
}

export const initialNotificationState: NotificationState = {
  unreadPrivateAmount: 0,
  unreadSystemAmount: 0,
  isNotificationOpened: false,
}

export const notificationState = createSlice({
  name: 'Notification',
  initialState: initialNotificationState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateUnreadPrivateNotificationAmount, (state, { payload }) => {
        const { unreadAmount } = payload

        state.unreadPrivateAmount = unreadAmount
        return state
      })
      .addCase(updateNotificationUnreadAmount, (state, { payload }) => {
        const { personal, system } = payload

        state.unreadPrivateAmount = personal
        state.unreadSystemAmount = system
        return state
      })
      .addCase(updateUnreadNotificationOnSocket, (state, { payload }) => {
        const { type } = payload

        if (type === 'personal') state.unreadPrivateAmount += 1
        else state.unreadSystemAmount += 1
        return state
      })
      .addCase(updateUnreadSystemNotificationAmount, (state, { payload }) => {
        const { unreadAmount } = payload

        state.unreadSystemAmount = unreadAmount
        return state
      })
      .addCase(updateOpenNotification, (state, { payload }) => {
        const { isOpen } = payload

        state.isNotificationOpened = isOpen
        return state
      })
  },
})

export default notificationState.reducer
