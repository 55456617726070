export enum RouteConfig {
  Home = '/',
  Games = '/games',
  GamesTag = '/games/{{tag}}',
  GameDetail = '/{{slug}}',
  Promotions = '/promotions',
  PromotionDetail = '/promotions/{{promotionSlug}}',
  Providers = '/providers',
  ProviderDetail = '/providers/{{code}}',
  Sport = '/sport',
  Spin = '/spin',
  SpinToWin = '/spin-to-win',
  CryptoCasino = '/crypto-casino',
  CasualGamer = '/easy-win',
  Highroller = '/highroller',
  BonusHunter = '/free-bonus',

  QueryDeposit = '/query/deposit',
  Profile = '/profile',
  Stake = '/staking',
  Referral = '/referral',
  HunnyJar = '/hunny-jar',
  Cashback = '/cashback',
  CashbackAbout = '/cashback/about',
  Blogs = '/news',
  TagDetails = '/news/tag/{{slug}}',
  PostDetails = '/news/{{slug}}',
  Maintenance = '/maintenance',
  BonusCenter = '/bonuses',
  FreeBonus = '/free-bonuses',
  Restriction = '/restriction',
  VIPClub = '/vip-club',
  WagerAdventure = '/wager-adventure',
  ShortCutPage = '/shortcut-page',
  BonusCenterAbout = '/bonuses/about',
  Tournament = '/tournament/{{id}}',

  MobileAccount = '/profile/account',
  MobileDashboard = '/profile/dashboard',
  FavoriteGames = '/games/favorites',
  RecentGames = '/games/recent',
  TransactionHistory = '/profile/transactions',
  SettingProfile = '/profile/setting',
  SettingSecurity = '/profile/setting/security',
  ProfileVipProgress = '/profile/level',
  DepositTransactionHistory = '/profile/transactions/deposit',
  WithdrawTransactionHistory = '/profile/transactions/withdraw',
  BetTransactionHistory = '/profile/transactions/bet',

  BonusTransactionHistory = '/profile/transactions/bonus',
  CashbackTransactionHistory = '/profile/transactions/cashback',
  LuckySpinTransactionHistory = '/profile/transactions/lucky-spin',
  HusdTransactionHistory = '/profile/transactions/husd',
  RankingBonusTransactionHistory = '/profile/transactions/ranking-bonus',
  RewardTransactionHistory = '/profile/transactions/reward',
  SwapTransactionHistory = '/profile/transactions/swap',
  TipTransactionHistory = '/profile/transactions/tip',
  ProfileNotification = '/profile/mobile-notification',
  ReferralTransactionHistory = '/profile/transactions/referral',
  DailyCheckinHistory = '/profile/transactions/daily-checkin',
  TournamentHistory = '/profile/transactions/tournament',

  MyRewards = '/profile/my-rewards',
  RedeemBonus = '/redeem/bonus/{{code}}',

  HunnyPoker = 'https://hunnypoker.com/',
  HunnyFarm = 'https://hunny.finance/',
  HunnySwap = 'https://hunnyswap.com/',
  HunnyBlogFinance = 'http://blog.hunny.finance/',
  HunnyDocs = 'https://docs.hunny.finance/products/hunnyplay',
  TermOfService = 'https://docs.hunny.finance/products/hunnyplay/hunnyplay-terms-and-conditions',
  PromotionMaterials = 'https://drive.google.com/drive/folders/1pTW_Mc0CcRQwzK6x3pLmxoDdgMley0eC',

  Twitter = 'https://twitter.com/HunnyPlay_',
  Telegram = 'https://t.me/HunnyPlay',
  Medium = 'https://medium.com/hunnyfinance',
  Discord = 'https://discord.com/invite/hunnyplay',
  Reddit = 'https://www.reddit.com/r/HunnyPlay_/',
  Facebook = 'https://www.facebook.com/HunnyPlay',
  Instagram = 'https://www.instagram.com/HunnyPlay_/',
  Youtube = 'https://www.youtube.com/@HunnyPlayGame',
  Affiliate = 'https://affiliate.hunnyplay.io/',
}
