import { createSlice } from '@reduxjs/toolkit'
import { TournamentType } from 'config/types/tournament'
import {
  initTournaments,
  updateActiveTournament,
  updateJoinTournament,
  updateJoinTournamentList,
  updateTournamentData,
} from './actions'

export interface TournamentState {
  tournaments: TournamentType[]
  activeTournamentId: number
}

const initialState: TournamentState = {
  tournaments: [],
  activeTournamentId: -1,
}

export const tournamentSlice = createSlice({
  name: 'tournament',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(initTournaments, (state, { payload }) => {
      state.tournaments = payload.data
      return state
    })
    builder.addCase(updateTournamentData, (state, { payload }) => {
      state.tournaments = state.tournaments.map((tournament) =>
        tournament.id === payload.data.id ? { ...tournament, details: payload.data } : tournament,
      )
      return state
    })
    builder.addCase(
      updateActiveTournament,
      (
        state,
        {
          payload: {
            data: { tournamentId },
          },
        },
      ) => {
        state.activeTournamentId = tournamentId
        return state
      },
    )
    builder.addCase(updateJoinTournamentList, (state, { payload }) => {
      state.tournaments = state.tournaments.map((tournament) => {
        const isJoined = payload.data[tournament.id]
        return {
          ...tournament,
          isJoined,
        }
      })
      return state
    })
    builder.addCase(updateJoinTournament, (state, { payload }) => {
      state.tournaments = state.tournaments.map((tournament) =>
        tournament.id === payload.data.tournamentId ? { ...tournament, isJoined: payload.data.isJoined } : tournament,
      )
      return state
    })
  },
})

export default tournamentSlice.reducer
