import { TournamentIdQueryParam } from 'config/types/tournament'
import { ToastContainerProps } from 'react-toastify'

export const DEFAULT_TIME = { days: 0, hours: 0, minutes: 0, seconds: 0 }

export const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
} as ToastContainerProps

export enum FetchingStatus {
  Unknown,
  Fetching,
  Fetched,
  Failed,
  Banned,
  Maintenance,
}

// copy of next.config.js images
export const IMG_SUPPORT_DOMAINS = [
  // {
  //   protocol: 'https:',
  //   hostname: 'media-new.hunny.finance',
  // },
  // {
  //   protocol: 'https:',
  //   hostname: 'media.hunny.finance',
  // },
  // {
  //   protocol: 'https:',
  //   hostname: 'review.hunnyplay.com',
  // },
]

export enum Time {
  SEC,
  MIN,
  HOUR,
  DAY,
  MONTH,
  YEAR,
}

export const emptyPagingReponse = {
  paging: {},
  items: [],
}

export const emptyPagingHunnyRequestResponse = {
  call: () => ({ data: { paging: {}, items: [] } }),
  cancel: () => {},
}

export const NULL_FUNCTION = () => {}

export const remainSearchParams = ['join', 'stag', 'bonus', TournamentIdQueryParam]

export const DISPLAY_AMOUNT = 8

export const FixedMilestonePoint = 'milestone_finish'

export const SETTING_SECURITY_MODAL_KEY = 'setting-security-modal-key'

export enum GameDetailsOnBoardModeEnum {
  PlayFreeSpin = 'playFreeSpin',
  PlayFreeSpinWithSelectedCurrency = 'playFreeSpinWithSelectedCurrency',
}
