import { createAction } from '@reduxjs/toolkit'

export const updateNotificationUnreadAmount = createAction<{ system: number; personal: number }>(
  'notification/updateNotificationUnreadAmount',
)
export const updateUnreadPrivateNotificationAmount = createAction<{ unreadAmount: number }>(
  'notification/updateUnreadPrivateNotificationAmount',
)
export const updateUnreadSystemNotificationAmount = createAction<{ unreadAmount: number }>(
  'notification/updateUnreadSystemNotificationAmount',
)
export const updateUnreadNotificationOnSocket = createAction<{ type: 'personal' | 'public' }>(
  'notification/updateUnreadNotificationOnSocket',
)

export const updateOpenNotification = createAction<{ isOpen: boolean }>('notification/updateOpenNotification')
