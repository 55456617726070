import { createAction } from '@reduxjs/toolkit'
import { TournamentDetailsData, TournamentType } from 'config/types/tournament'

export const initTournaments = createAction<{ data: TournamentType[] }>('tournament/initTournaments')
export const updateTournamentData = createAction<{ data: TournamentDetailsData }>('tournament/updateTournamentData')
export const updateJoinTournamentList = createAction<{ data: Record<string, boolean> }>(
  'tournament/updateJoinTournamentList',
)
export const updateJoinTournament = createAction<{ data: { tournamentId: number; isJoined: boolean } }>(
  'tournament/updateJoinTournament',
)

export const updateActiveTournament = createAction<{ data: { tournamentId: number } }>(
  'tournament/updateActiveTournament',
)
